import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/codeReceive',
    name:'codeReceive',
    component: () => import('../views/codeReceive.vue'),
    meta: {
      title: '领取优惠'
    }
  },
  {
    path: '/pay',
    name:'pay',
    component: () => import('../views/pay.vue'),
    meta: {
      title: '停车缴费'
    }
  },
  {
    path: '/orderList',
    name:'orderList',
    component: () => import('../views/list.vue'),
    meta: {
      title: '订单记录'
    }
  },
  {
    path: '/orderDeatil',
    name:'orderDeatil',
    component: () => import('../views/detail.vue'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/feedback',
    name:'feedback',
    component: () => import('../views/feedback.vue'),
    meta: {
      title: '便民举报平台'
    }
  },
  {
    path: '/orderMultiple',
    name: 'OrderMultiple',
    component: () => import('../views/multiple'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/orderSuccess',
    name: 'OrderSuccess',
    component: () => import('../views/success'),
    meta: {
      title: '支付成功'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/timeQrCode',
    name:'timeQrCode',
    component: () => import('../views/timeQrCode.vue'),
    meta: {
      title: '酒店时长二维码'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
